// @flow

import style from "./style.module.scss";

import React, { PureComponent, Fragment } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

type Props = {
    background: *,
    logo: *,
};

export const query = graphql`
    fragment Intro on Query {
        background: file(relativePath: { eq: "intro.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                sqip(numberOfPrimitives: 50, blur: 5) {
                    dataURI
                }
            }
        }
        logo: file(relativePath: { eq: "logo.svg" }) {
            publicURL
        }
    }
`;

/**
 * Intro
 */
export default class Intro extends PureComponent<Props> {
    /**
     * Render
     */
    render() {
        const { background, logo } = this.props;

        return (
            <Fragment>
                <Img
                    className={style.background}
                    fluid={{
                        ...background.childImageSharp.fluid,
                        base64: background.childImageSharp.sqip.dataURI,
                    }}
                />
                <div className={style.content}>
                    <img src={logo.publicURL} />
                    <div className={style.info}>
                        <p>
                            Voor informatie en reservaties, bel{" "}
                            <a href="tel:+32497402117">+32 (0)497 40 21 17</a>{" "}
                            of stuur een e-mail naar{" "}
                            <a href="mailto:info@pizzaroule.be">
                                info@pizzaroule.be
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </Fragment>
        );
    }
}
