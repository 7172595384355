// @flow

import "./index.scss";

import React, { Fragment, type Node } from "react";

type Props = {
    children: Node,
};

/**
 * Page layout and global styling
 */
const Layout = ({ children }: Props) => (
    <Fragment>
        <main>{children}</main>
    </Fragment>
);

export default Layout;
