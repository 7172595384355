// @flow

import React from "react";
import { graphql } from "gatsby";

import { Layout, Intro } from "../components";

/**
 * Homepage
 */
const IndexPage = ({ data: { background, logo } }: *) => (
    <Layout>
        <Intro background={background} logo={logo} />
    </Layout>
);

export default IndexPage;

export const query = graphql`
    query {
        ...Intro
    }
`;
